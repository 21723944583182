export const userAgent = (req) => {
  if (req) return req.headers['user-agent']
  else if (process.browser && navigator) {
    return navigator.userAgent || navigator.vendor || window.opera
  } else return ''
}

export const isMobile = (req, width) => {
  const innerWidth = width || 992 //768
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|windows phone|Opera Mini/i.test(
      userAgent(req)
    )
  )
    return true
  else if (process.browser) return window.innerWidth <= innerWidth
  else return false
}

export const isChrome = (req) => {
  return /Chrome/i.test(userAgent(req))
}
export const isSafari = (req) => {
  return /Safari/i.test(userAgent(req))
}
export const isFirefox = (req) => {
  return /Firefox/i.test(userAgent(req))
}
export const isAndroid = (req) => {
  return /Android/i.test(userAgent(req))
}
export const isBlackberry = (req) => {
  return /BlackBerry/i.test(userAgent(req))
}
export const isIos = (req) => {
  return /iPhone|iPad|iPod/i.test(userAgent(req))
}
export const isOpera = (req) => {
  return /Opera Mini/i.test(userAgent(req))
}
export const isWindowsMobile = (req) => {
  return /IEMobile|windows phone/i.test(userAgent(req))
}
