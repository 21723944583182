import '../assets/style/style.scss'
import { GlobalContextProvider } from '../contexts/globalcontextt'
import ProgressBar from '@components/ui/progress-bar'

function MyApp({ Component, pageProps }) {
  return (
    <GlobalContextProvider pageProps={pageProps}>
      <ProgressBar />
      <Component {...pageProps} />
    </GlobalContextProvider>
  )
}

export default MyApp
