import { useState, createContext, useEffect } from 'react'
import { getAllPosition } from 'utils/apis'
import { loginCheck } from 'utils/general'

export const GlobalContext = createContext()

export const GlobalContextProvider = (props) => {
  const { children, pageProps } = props

  // const [currentUser, setCurrentUser] = useState({})
  // const [isLoading, setIsLoading] = useState(true)
  const [isLogin, setIsLogin] = useState(loginCheck())
  const [loginCallBack, setLoginCallBack] = useState(false)
  const [serviceDialog, setServiceDialog] = useState({})
  const [multipleApplyDialog, setMultipleApplyDialog] = useState()
  const [applyCountDown, setApplyCountDown] = useState(0)
  const [jobSearchLoading, setJobSearchLoading] = useState(false)
  const [jobSearchDetailLoading, setJobSearchDetailLoading] = useState(false)
  const [gtmLoaded, setGtmLoaded] = useState(false)
  const [actionLoading, setActionLoading] = useState(false)
  const [allPositionList, setAllPositionList] = useState([])

  const [positionKeyword, setPositionKeyword] = useState(
    pageProps.keyword || undefined
  )
  const [positionName, setPositionName] = useState(
    pageProps.positionName || undefined
  )
  const [searchLocation, setSearchLocation] = useState(
    pageProps.searchLocation || {}
  )
  const [locationKeyword, setLocationKeyword] = useState(
    pageProps?.searchLocation?.lc || undefined
  )

  useEffect(() => {
    if ((pageProps?.keyword ?? '') !== (positionKeyword ?? '')) {
      setPositionKeyword(pageProps?.keyword)
    }

    if (
      JSON.stringify(pageProps?.searchLocation || {}) !==
      JSON.stringify(searchLocation || {})
    ) {
      setSearchLocation(pageProps?.searchLocation || {})
    }

    if (pageProps?.searchLocation?.lc !== searchLocation?.lc) {
      setLocationKeyword(pageProps?.searchLocation?.lc)
    }
  }, [pageProps.keyword, pageProps.searchLocation])

  const setOnlogin = (value) => {
    setIsLogin(value)
    if (value) setLoginCallBack(value)
  }

  const setOnAllPositionList = async () => {
    if (allPositionList?.length) return
    setAllPositionList((await getAllPosition()) || [])
  }

  const value = {
    // currentUser,
    // isLoading,
    // setCurrentUser,
    // setIsLoading,

    isLogin,
    setOnlogin,
    loginCallBack,
    setLoginCallBack,
    serviceDialog,
    setServiceDialog,
    multipleApplyDialog,
    setMultipleApplyDialog,
    applyCountDown,
    setApplyCountDown,
    jobSearchLoading,
    setJobSearchLoading,
    jobSearchDetailLoading,
    setJobSearchDetailLoading,
    gtmLoaded,
    setGtmLoaded,
    actionLoading,
    setActionLoading,
    allPositionList,
    setOnAllPositionList,

    positionKeyword,
    setPositionKeyword,
    positionName,
    setPositionName,
    locationKeyword,
    setLocationKeyword,
    searchLocation,
    setSearchLocation,
  }

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}
