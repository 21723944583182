import {
  setCookies,
  removeCookies,
  checkCookies,
  getCookies,
} from 'utils/cookies-next'
import getSlug from 'speakingurl'
import services, { returnDeviceId } from 'services'
import qs from 'qs'
import sha256 from 'sha256'

const searchIdStoreName = 'searchIds'
const searchIdClickItemStoreName = 'searchIdClickItems'

export const logout = (ctx) => {
  removeCookies(ctx, 'BlueCollar_UserToken')
  removeCookies(ctx, 'BlueCollar_UserPhone')
  removeCookies(ctx, 'BlueCollar_UserDeviceId')

  removeCookies(ctx, 'BlueCollar_HomeLocationLat')
  removeCookies(ctx, 'BlueCollar_HomeLocationLng')
  removeCookies(ctx, 'BlueCollar_HomeAddress')
  removeCookies(ctx, 'BlueCollar_HomeShortAddress')
  removeCookies(ctx, 'user_id')

  if (isAvailablelocalStorage()) {
    localStorage.removeItem('candidate')
    localStorage.removeItem('account')
    checkGa4Storage()
  }
}

export const loginCheck = (ctx) => {
  if (
    checkCookies(ctx, 'BlueCollar_UserToken') &&
    checkCookies(ctx, 'BlueCollar_UserPhone')
  ) {
    return true
  } else return false
}

/*export const temporaryApplicationCheck = (ctx) => {
  return checkCookies(ctx, 'BlueCollar_TemporaryApplication')
}

export const temporaryApplicationSet = (ctx) => {
  setCookies(ctx, 'BlueCollar_TemporaryApplication', 'true', { expires: 1 })
}*/

export const setToken = (ctx, token, accountId) => {
  const bearerToken = `Bearer ${token}`
  setCookies(ctx, 'BlueCollar_UserToken', bearerToken, {
    expires: 1,
    // httpOnly: true,
    // sameSite: 'Lax',
    // domain: '.isinolsun.com',
    // secure: true, //process.env.NEXT_PUBLIC_BUILD_ENV != 'local',
  })
  if (accountId) setCookies(ctx, 'user_id', accountId, { expires: 1 })

  setStorageCandidate(ctx)
}

export const getToken = async (paramObj) => {
  const params = {
    client_type: 20,
    grant_type: 'password',
    client_id: 'BlueCollarWebUserClient',
    client_secret: 'A79733CC-F67D-44AD-8DC7-6829846912CE',
    device_id: returnDeviceId(),
    ...paramObj,
  }

  const config = {
    path: '/api/token',
    type: 'post',
  }

  let resp = {}
  try {
    resp = await services.wrapperTokenApi(null, config, params)
  } catch (err) {
    return false
  }

  if (resp && resp.data) {
    return resp.data.access_token
  } else return false
}

export const checkGa4Storage = (data) => {
  const userNumber =
    data?.phone?.countryCallingCode +
    data?.phone?.areaCode +
    data?.phone?.number

  const ga4Storage = {
    user_id: data ? data.candidateId.toString() : null,
    user_login_status: data ? 'yes' : null,
    user_login_type: data ? 'employee' : null,
    user_email: data?.emailAddress ? sha256(data.emailAddress) : null,
    user_phone: data ? sha256(userNumber) : null,
  }
  localStorage.setItem('ga4_user_properties', JSON.stringify(ga4Storage))
}

export const setStorageCandidate = async (ctx, data) => {
  if (!loginCheck()) return

  let result = null
  if (data) result = data
  else {
    let resp
    try {
      resp = await services.wrapper(ctx, services.configs.candidates.get)
    } catch (error) {
      //
    }

    if (resp && resp.data && resp.data.result) {
      result = resp.data.result
    }
  }

  if (result) {
    if (isAvailablelocalStorage()) {
      localStorage.setItem('candidate', JSON.stringify(result))
      checkGa4Storage(result)
    }
    setCookies(ctx, 'BlueCollar_HomeLocationLat', result.latitude, {
      expires: 1,
    })
    setCookies(ctx, 'BlueCollar_HomeLocationLng', result.longitude, {
      expires: 1,
    })
    setCookies(ctx, 'BlueCollar_HomeAddress', result.address, { expires: 1 })
    setCookies(ctx, 'BlueCollar_HomeShortAddress', result.shortAddress, {
      expires: 1,
    })
  }
  return result || {}
}

export const setStorageAccount = (data) => {
  if (isAvailablelocalStorage())
    localStorage.setItem('account', JSON.stringify(data))

  return
}

export const isAvailablelocalStorage = () => {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export const isAvailableSessionStorage = () => {
  const test = 'test'
  try {
    sessionStorage.setItem(test, test)
    sessionStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export const redirectLoginPage = (ctx) => {
  const redirectUrl = `${process.env.NEXT_PUBLIC_MAIN_URL2}/is-ilanlari?login`

  if (redirectUrl) {
    if (!process.browser && ctx && ctx.res) {
      ctx.res.writeHead(302, { Location: redirectUrl })
      ctx.res.end()
    } else if (process.browser) {
      //Router.pushRoute('blog', {slug: 'hello-world'})
      window.location.href = redirectUrl
    }
  }
}

export const redirectCurrentPage = (ctx) => {
  if (!process.browser && ctx && ctx.res) {
    const redirectUrl = process.env.NEXT_PUBLIC_MAIN_URL2 + ctx.req.url
    ctx.res.writeHead(302, { Location: redirectUrl })
    ctx.res.end()
  } else if (process.browser) {
    //Router.route
    location.reload()
  }
}

export const redirectUrl = (ctx, url) => {
  if (!process.browser && ctx && ctx.res) {
    ctx.res.writeHead(302, { Location: url })
    ctx.res.end()
  } else if (process.browser) {
    //Router.route
    //Router.pushRoute('blog', {slug: 'hello-world'})
    window.location.href = url
  }
}

export const redirectUrl301 = (ctx, url) => {
  if (!process.browser && ctx && ctx.res) {
    ctx.res.writeHead(301, { Location: url })
    ctx.res.end()
  } else if (process.browser) {
    window.location.href = url
  }
}

export const toUrl = (str, symbols = false) => {
  return getSlug(str, { lang: 'tr', symbols })
}

export const addScriptControl = () => {
  return process.browser
}

export const townUrlControl = (town, city, position) => {
  if (toUrl(town).replace('-', ' ').includes(toUrl(city).replace('-', ' ')))
    return toUrl(town + '-' + position)
  else return toUrl(city + '-' + town + '-' + position)
}

export const removeGeoLocation = () => {
  removeCookies(null, 'BlueCollar_UserLocationLat')
  removeCookies(null, 'BlueCollar_UserLocationLng')
  removeCookies(null, 'BlueCollar_UserLocationAddress')
  removeCookies(null, 'BlueCollar_UserLocationShortAddress')
}

export const setGeoLocation = (position) => {
  setCookies(null, 'BlueCollar_UserLocationLat', position.coords.latitude, {
    expires: 30,
  })
  setCookies(null, 'BlueCollar_UserLocationLng', position.coords.longitude, {
    expires: 30,
  })
}

export const setLocationAddress = (locationAddress) => {
  setCookies(null, 'BlueCollar_UserLocationAddress', locationAddress.address, {
    expires: 30,
  })
  setCookies(
    null,
    'BlueCollar_UserLocationShortAddress',
    locationAddress.townName + ', ' + locationAddress.city,
    {
      expires: 30,
    }
  )
}

export const checkGeolocationPermission = (onChangeEvent = () => {}) => {
  return new Promise((resolve, reject) => {
    navigator?.permissions
      ?.query({ name: 'geolocation' })
      .then((result) => {
        result.onchange = () => onChangeEvent(result?.state)
        resolve(result?.state)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCoordinates = async (timeout = 10000) => {
  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      resolve(null)
    }, timeout)

    navigator?.geolocation?.getCurrentPosition(
      (position) => {
        clearTimeout(timer)
        resolve(position)
      },
      () => {
        clearTimeout(timer)
        resolve(null)
      }
    )
  })
}

export const getAddress = async (position) => {
  const lat = position.coords.latitude
  const long = position.coords.longitude

  const params = {
    latitude: lat,
    longitude: long,
  }

  let result
  try {
    result = await services.wrapper(
      null,
      services.configs.places.geo.get,
      params
    )
  } catch (err) {
    //
  }

  if (result) return result

  return false
}

export const geoLocationProcess = async () => {
  let position

  try {
    position = await getCoordinates()
  } catch (err) {
    //
  }

  if (!position) {
    removeGeoLocation()
    return false
  }

  setGeoLocation(position)

  let respAddress

  try {
    respAddress = await getAddress(position)
  } catch (err) {
    //
  }

  if (respAddress && respAddress.data && respAddress.data.result) {
    setLocationAddress(respAddress.data.result)

    return { position, location: respAddress.data.result }
  }

  return false
}

export const sliceIntoChunks = (arr, chunkSize) => {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

export const getJobType = (workType) => {
  const jobTypes = {
    1: { title: 'Tam Zamanlı', slug: 'tam-zamanli' },
    2: { title: 'Part Time', slug: 'yari-zamanli' },
    4: { title: 'Hizmet', slug: 'hizmet' },
  }

  return jobTypes?.[workType]
}

export const addToMultipleApplySessionStorage = (type, value) => {
  if (isAvailableSessionStorage()) {
    if (type === 'apply') {
      sessionStorage?.setItem('lastApplyJobId', JSON.stringify(value))
      sessionStorage?.setItem('actionTimestamp', new Date())
    } else if (type === 'seen') {
      sessionStorage?.setItem('lastSeenJobId', JSON.stringify(value))
    }
  }
}
export const calcMultipleApplyTimeDifference = () => {
  const currentTime = new Date()
  const actionTimestamp = sessionStorage?.getItem('actionTimestamp')

  if (actionTimestamp) {
    const savedTime = new Date(actionTimestamp)
    const timeDifferenceInSeconds = Math.floor((currentTime - savedTime) / 1000)

    return timeDifferenceInSeconds
  }
}

export const removeFromSessionStorage = (key) => {
  sessionStorage.removeItem(key)
}

export const undefinedToNull = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      undefinedToNull(obj[key])
    } else if (obj[key] === undefined) {
      obj[key] = null
    }
  }
  return obj
}

export const getGa4AgreementTypeValue = (agreementType) => {
  switch (agreementType) {
    case 'candidateAgreement':
      return 'hizmet-sozlesmesi'
    case 'clarificationAgreement':
      return 'aydinlatma-metni'
    default:
      return undefined
  }
}

export const addResizePath = (url, size = '120/120') => {
  if (url?.includes('.mncdn.com')) {
    return url?.replace('.com', `.com/mnresize/${size}`)
  }
  return url
}

export const mappingCompanyImageUrl = (companyImageUrl, companyType) => {
  const dummyImgUrl = `${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/company-profile-`
  const imageUrl = companyImageUrl
    ? addResizePath(companyImageUrl)
    : `${dummyImgUrl}${companyType === 1 ? 'personal' : 'company'}.png`
  return imageUrl
}

export const redirectToJobListings = (searchParameters) => {
  window.location.href =
    `${process.env.NEXT_PUBLIC_MAIN_URL2}/is-ilanlari` +
    (Object.keys(searchParameters).length > 0
      ? '?' + qs.stringify(searchParameters)
      : '')
}

export const ga4EventButtonClick = (buttonType) => {
  dataLayer.push({
    event: 'ga4_event',
    event_name: 'button_click',
    event_parameters: {
      button: buttonType,
    },
  })
}

export const generateSearchId = () => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  for (let i = 0; i < 32; i++) {
    result += characters
      .charAt(Math.floor(Math.random() * characters.length))
      .toString()
  }
  return result
}

export const addSearchId = ({
  itemId,
  searchId,
  listName,
  itemIndex,
  type = 1,
}) => {
  if (isAvailablelocalStorage()) {
    let searchIds = []
    const storeName = type == 1 ? searchIdStoreName : searchIdClickItemStoreName

    if (localStorage.getItem(storeName)) {
      searchIds = JSON.parse(localStorage.getItem(storeName))
      searchIds = searchIds.filter((e) => e.itemId != itemId)
      if (searchIds.length > 50) searchIds = searchIds.slice(0, 50)
    }

    const newIdObj = {
      itemId,
      searchId,
      listName,
      itemIndex,
    }

    searchIds.unshift(newIdObj)
    localStorage.setItem(storeName, JSON.stringify(searchIds))

    return newIdObj
  }

  return null
}

export const deleteOldSearchId = ({ oldSearchId, type = 1 }) => {
  if (!oldSearchId?.searchId) return
  if (isAvailablelocalStorage()) {
    let searchIds = []

    const storeName = type == 1 ? searchIdStoreName : searchIdClickItemStoreName

    if (localStorage.getItem(storeName)) {
      searchIds = JSON.parse(localStorage.getItem(storeName))
      searchIds = searchIds.filter((e) => e.searchId != oldSearchId.searchId)
    }

    localStorage.setItem(storeName, JSON.stringify(searchIds))
  }
}

export const getSearchId = ({ itemId, type = 1 }) => {
  let result
  const storeName = type == 1 ? searchIdStoreName : searchIdClickItemStoreName

  if (itemId && isAvailablelocalStorage()) {
    if (localStorage.getItem(storeName)) {
      const searchIds = JSON.parse(localStorage.getItem(storeName))
      result = searchIds.find((e) => e.itemId == itemId)
    }
  }

  if (!result) {
    return {
      searchId: 0,
      itemIndex: 0,
      listName: 0,
      itemId: 0,
    }
  }

  return result
}

export const clearSearchIdUrl = (url) => {
  let resultUrl = url
  if (typeof URLSearchParams !== 'undefined') {
    const parsedUrl = new URL(
      url.startsWith('http') ? url : process.env.NEXT_PUBLIC_MAIN_URL2 + url
    )
    const params = new URLSearchParams(parsedUrl.search)
    params.delete('pn')
    resultUrl = parsedUrl.pathname
    if (params.size > 0) {
      const newUrl = new URL(
        `${parsedUrl.origin}${parsedUrl.pathname}?${params}`
      )
      resultUrl = newUrl.pathname + newUrl.search
    }
  }

  return resultUrl
}

export const filterLabel = (options) => {
  if (Array.isArray(options) && options?.length > 0) {
    if (options?.length === 1) {
      return toUrl(options[0]?.text)
    } else {
      return options?.map((option) => toUrl(option?.text))?.join('|')
    }
  }
  return undefined
}

export const calcFilterLabels = (options) => {
  const labels = {
    release_date: 'tum-zamanlar',
    sort: 'akilli-siralama',
    job_type: 'not-selected',
    application_type: 'tumu',
    urgent: 'not-selected',
    disabled_advert: 'not-selected',
    salary: 'not-selected',
    approved_employer: 'not-selected',
  }

  if (options) {
    if (options.OtherFilter) {
      if (options.OtherFilter.find((e) => e.id == 0)) labels.urgent = 'yes'
      if (options.OtherFilter.find((e) => e.id == 2)) labels.salary = 'yes'
      if (options.OtherFilter.find((e) => e.id == 1))
        labels.disabled_advert = 'yes'
      if (options.OtherFilter.find((e) => e.id == 3))
        labels.approved_employer = 'yes'
    }

    labels.sort = filterLabel(options.JobSortTypes) || labels.sort
    labels.job_type = filterLabel(options.JobWorkTypes) || labels.job_type
    labels.application_type =
      filterLabel(options.ApplicationTypes) || labels.application_type
    labels.release_date =
      filterLabel(options.PublishDateFilters) || labels.release_date
  }

  return labels
}

export const locationMinLength = 3
export const positionMinLength = 3
export const searchIdFeature = true

export function locationIdCheck({ cityName, townName }) {
  if (!cityName && !townName) return undefined
  let locationId = ''
  if (cityName) locationId += toUrl(cityName)
  if (townName) locationId += cityName ? `/${toUrl(townName)}` : toUrl(townName)

  return locationId
}

export function checkApplyJobId() {
  const applyJobId =
    JSON?.parse(sessionStorage?.getItem('lastApplyJobId')) ||
    JSON?.parse(sessionStorage?.getItem('lastSeenJobId'))

  return applyJobId
}

export const getSuggestJobsAll = async (
  ctx,
  userLocationLat,
  userLocationLng
) => {
  const params = {
    pageNumber: 0,
    pageSize: 9,
    isDistanceSortEnabled: true,
    isSmartSortingEnabled: true,
    isShowOnHomePage: true,
    ...(userLocationLat && { latitude: userLocationLat, hasLatitude: true }),
    ...(userLocationLng && { longitude: userLocationLng, hasLongitude: true }),
  }
  const result = []
  const baseHref = `${process.env.NEXT_PUBLIC_MAIN_URL2}`

  try {
    const [respFeatured, respPartTime, respUrgent, respService] =
      await Promise.all([
        services.wrapper(ctx, services.configs.jobs.search.get, params),
        services.wrapper(ctx, services.configs.jobs.search.get, {
          ...params,
          workType: 2,
        }),
        services.wrapper(ctx, services.configs.jobs.search.get, {
          ...params,
          displayType: 4,
        }),
        services.wrapper(ctx, services.configs.jobs.search.get, {
          ...params,
          workType: 4,
        }),
      ])

    if (respFeatured?.data?.result?.jobList?.items) {
      result.push({
        id: 0,
        name: 'Öne Çıkan',
        slug: 'one-cikan',
        href: `${baseHref}/is-ilanlari`,
        jobs: respFeatured.data.result.jobList.items,
      })
    }

    if (respPartTime?.data?.result?.jobList?.items) {
      result.push({
        id: 1,
        name: 'Part Time',
        slug: 'part-time',
        href: `${baseHref}/is-ilanlari/part-time`,
        jobs: respPartTime.data.result.jobList.items,
      })
    }

    if (respUrgent?.data?.result?.jobList?.items) {
      result.push({
        id: 2,
        name: 'Acil İlan',
        slug: 'acil-ilan',
        href: `${baseHref}/is-ilanlari?isUrgent=true`,
        jobs: respUrgent.data.result.jobList.items,
      })
    }

    if (respService?.data?.result?.jobList?.items) {
      result.push({
        id: 3,
        name: 'Hizmet',
        slug: 'hizmet',
        href: `${baseHref}/hizmetler`,
        jobs: respService.data.result.jobList.items,
      })
    }
  } catch (error) {
    //
  }

  return result
}

export const formatPhoneRaw = (phone) => {
  let result = phone
  try {
    if (phone?.length == 10) {
      const parts = [
        `(${phone.substr(0, 3)})`,
        phone.substr(3, 3),
        phone.substr(6, 2),
        phone.substr(8, 2),
      ]
      result = parts.join(' ')
    }
    return result
  } catch (error) {
    //
  }

  return result
}

export const getMultiPositionName = (positionList) => {
  return positionList?.map((position) => position?.positionName)?.join(', ')
}

export const removeEmojis = (value) => {
  const characterFilter =
    /[^\u0020-\u007E\u00A0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF]/g
  return value?.replace(characterFilter, '')
}

export const changeHostToMainUrl = (url) => {
  let result = url
  try {
    const parsedUrl = new URL(url)
    result = process.env.NEXT_PUBLIC_MAIN_URL2 + parsedUrl.pathname
  } catch (error) {
    //
  }
  return result
}

export default {
  logout,
  redirectLoginPage,
  redirectCurrentPage,
  redirectUrl,
  toUrl,
  isAvailablelocalStorage,
  setStorageCandidate,
  setStorageAccount,
  isAvailableSessionStorage,
  addScriptControl,
  townUrlControl,
  geoLocationProcess,
  sliceIntoChunks,
  getJobType,
  addToMultipleApplySessionStorage,
  calcMultipleApplyTimeDifference,
  removeFromSessionStorage,
  getGa4AgreementTypeValue,
  addResizePath,
  checkGeolocationPermission,
  redirectToJobListings,
  ga4EventButtonClick,
  locationMinLength,
  positionMinLength,
  locationIdCheck,
  checkApplyJobId,
  getMultiPositionName,
  removeEmojis,
}
