//return $"{value:#,0}".Replace(",", ".");

export const formatNumberType1 = (value) => {
  return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export const changeQueryParameter = (uri, key, value) => {
  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  const separator = uri.indexOf('?') !== -1 ? '&' : '?'
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2')
  } else {
    return uri + separator + key + '=' + value
  }
}

export const removeParam = (key, sourceURL) => {
  let rtn = sourceURL.split('?')[0],
    param = '',
    params_arr = [],
    queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : ''
  if (queryString !== '') {
    params_arr = queryString.split('&')
    for (let i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split('=')[0]
      if (param === key) {
        params_arr.splice(i, 1)
      }
    }
    if (params_arr.length > 0) {
      rtn = rtn + '?' + params_arr.join('&')
    }
  }
  return rtn
}

export const updateUrl = (url, key, value) => {
  if (value !== undefined) {
    value = encodeURI(value)
  }
  let hashIndex = url.indexOf('#') | 0
  if (hashIndex === -1) hashIndex = url.length | 0
  const urls = url.substring(0, hashIndex).split('?')
  const baseUrl = urls[0]
  let parameters = ''
  const outPara = {}
  if (urls.length > 1) {
    parameters = urls[1]
  }
  if (parameters !== '') {
    parameters = parameters.split('&')
    for (const k in parameters) {
      let keyVal = parameters[k]
      keyVal = keyVal.split('=')
      const ekey = keyVal[0]
      let evalue = ''
      if (keyVal.length > 1) {
        evalue = keyVal[1]
      }
      outPara[ekey] = evalue
    }
  }

  if (value !== undefined) {
    outPara[key] = value
  } else {
    delete outPara[key]
  }
  parameters = []
  for (const k in outPara) {
    parameters.push(k + '=' + outPara[k])
  }

  let finalUrl = baseUrl

  if (parameters.length > 0) {
    finalUrl += '?' + parameters.join('&')
  }

  return finalUrl + url.substring(hashIndex)
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return turkishToUpper(s.charAt(0)) + s.slice(1)
}

export const turkishToLower = (s) => {
  const letters = { İ: 'i', I: 'ı', Ş: 'ş', Ğ: 'ğ', Ü: 'ü', Ö: 'ö', Ç: 'ç' }
  s = s.replace(/(([İIŞĞÜÇÖ]))/g, function (letter) {
    return letters[letter]
  })
  return s.toLowerCase()
}

export const turkishToUpper = (s) => {
  const letters = { i: 'İ', ş: 'Ş', ğ: 'Ğ', ü: 'Ü', ö: 'Ö', ç: 'Ç', ı: 'I' }
  s = s.replace(/(([iışğüçö]))/g, function (letter) {
    return letters[letter]
  })
  return s.toUpperCase()
}

export const formatDate = (dateStr) => {
  if (!dateStr || dateStr.length == 0) return ''
  let arr = dateStr.split(/[.\*+-/_ ]/)

  try {
    if (arr.length > 2) {
      let dateV1 = new Date(arr[2], arr[1] - 1, arr[0])
      let dateV2 = new Date(arr[0], arr[1] - 1, arr[2])
      let dateV3 = new Date(arr[1], arr[0] - 1, arr[2])
      if (arr[0].toString().length >= 4) return (dateStr = dateV2)
      else if (arr[1].toString().length >= 4) return (dateStr = dateV3)
      else {
        return (dateStr = dateV1)
      }
    }
  } catch (err) {
    return ''
  }

  return ''
}

const decodeUnicode = (str) => {
  try {
    return JSON.parse(`"${str}"`)
  } catch (e) {
    return str
  }
}

export const normalizeString = (str) => {
  return decodeUnicode(str)
    .replace(/İ/g, 'i')
    .replace(/I/g, 'i')
    .toLowerCase()
    .replace(/ı/g, 'i')
    .replace(/ç/g, 'c')
    .replace(/ş/g, 's')
    .replace(/ğ/g, 'g')
    .replace(/ü/g, 'u')
    .replace(/ö/g, 'o')
}
