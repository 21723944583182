import { checkCookies, getCookies, setCookies } from 'utils/cookies-next'
import configs from './configs'
import axios from 'axios'
import qs from 'qs'
import {
  logout,
  redirectLoginPage,
  redirectCurrentPage,
  loginCheck,
  setToken,
} from 'utils/general'
import md5 from 'md5'
import { isMobile } from 'utils/mobileDetect'

axios.defaults.headers['Accept-Language'] = 'tr-TR'
axios.defaults.headers['Source-Version'] = process.env.NEXT_PUBLIC_VERSION

const userApiInterface = axios.create()
userApiInterface.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL
userApiInterface.defaults.headers.post['Content-Type'] = 'application/json'

const publicApiInterface = axios.create()
publicApiInterface.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL
publicApiInterface.defaults.headers.post['Content-Type'] = 'application/json'

const tokenApi = axios.create()
tokenApi.defaults.baseURL = process.env.NEXT_PUBLIC_TOKEN_API_URL
tokenApi.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

let _ctx = null
export const wrapper = async (ctx, servicePathObj, params, header) => {
  _ctx = ctx
  let isLogin = loginCheck(ctx)
  let bearerToken = ''

  if (ctx && ctx.bearerToken) bearerToken = ctx.bearerToken
  else {
    if (isLogin && !servicePathObj.isPublic && !servicePathObj.isAts)
      bearerToken = getCookies(ctx, 'BlueCollar_UserToken')
    else if (!isLogin && servicePathObj.isAuth) {
      logout(ctx)
      redirectLoginPage(ctx)
    } else {
      if (checkCookies(ctx, 'BlueCollar_PublicToken'))
        bearerToken = getCookies(ctx, 'BlueCollar_PublicToken')
      else if (!servicePathObj.withCredentials)
        bearerToken = await setPublicToken(ctx)
    }
  }

  const config = returnConfig(ctx, header, servicePathObj, bearerToken, isLogin)

  if (servicePathObj.type == 'get' || servicePathObj.isQuery)
    config.params = params
  else config.data = params

  if (servicePathObj.withCredentials) {
    delete config.headers.Authorization
    config.withCredentials = true
  }

  if (isLogin && !servicePathObj.isPublic && !servicePathObj.isAts)
    return userApiInterface(config)
  else return publicApiInterface(config)
}

const returnConfig = (ctx, header, servicePathObj, bearerToken, isLogin) => {
  const deviceId = returnDeviceId(ctx)
  const deviceModel = returnDeviceModel(ctx) || ''
  const sourceType = isMobile(ctx?.req) ? 9 : 8
  const headerParams = header ? header : {}

  return {
    url: servicePathObj.baseURL
      ? servicePathObj.baseURL + servicePathObj.path
      : servicePathObj.path,
    method: servicePathObj.type,
    headers: {
      Authorization: bearerToken ? bearerToken : '',
      'Device-Id': deviceId,
      'Source-DeviceModel': deviceModel,
      'Source-Type': sourceType,
      ...headerParams,
    },
    isLogin,
    servicePathObj,
  }
}

export const returnDeviceId = (ctx) => {
  let deviceId = ''
  if (checkCookies(ctx, 'BlueCollar_UserDeviceId')) {
    deviceId = getCookies(ctx, 'BlueCollar_UserDeviceId')
  } else {
    deviceId = md5('BlueCollar_' + new Date())
    setCookies(ctx, 'BlueCollar_UserDeviceId', deviceId, {
      expires: 1,
      // httpOnly: true,
      // sameSite: 'Lax',
      // secure: true, //process.env.NEXT_PUBLIC_BUILD_ENV != 'local',
    })
  }
  return deviceId
}

export const returnDeviceModel = (ctx) => {
  if (ctx && ctx.req && ctx.req.headers) return ctx.req.headers['user-agent']
  else if (process.browser && navigator.userAgent) return navigator.userAgent
  else return ''
}

export const wrapperTokenApi = async (ctx, servicePathObj, params) => {
  const deviceId = returnDeviceId(ctx)
  const deviceModel = returnDeviceModel(ctx) || ''
  const sourceType = isMobile(ctx?.req) ? 9 : 8

  const config = {
    url: servicePathObj.path,
    method: servicePathObj.type,
    headers: {
      'Device-Id': deviceId,
      'Source-DeviceModel': deviceModel,
      'Source-Type': sourceType,
    },
  }

  config.data = qs.stringify(params)

  return tokenApi(config)
}

userApiInterface.interceptors.request.use(
  (config) => {
    if (config.params) {
      config.paramsSerializer = (params) => {
        return qs.stringify(params)
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

publicApiInterface.interceptors.request.use(
  (config) => {
    if (config.params) {
      config.paramsSerializer = (params) => {
        return qs.stringify(params)
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

userApiInterface.interceptors.response.use(
  (response) => {
    return Promise.resolve(response)
  },
  (error) => {
    const code = parseInt(error.response && error.response.status)
    let originalRequest = error.config

    if (code == 401) {
      const phone = getCookies(_ctx, 'BlueCollar_UserPhone')
      const deviceId = getCookies(_ctx, 'BlueCollar_UserDeviceId')
      if (!originalRequest.isRetry && phone && deviceId) {
        originalRequest.isRetry = true
        return setUserToken(_ctx, originalRequest, phone, deviceId).then(
          (bearerToken) => {
            originalRequest.headers.Authorization = bearerToken
            return userApiInterface(originalRequest)
          }
        )
      } else {
        logout(_ctx)
        if (originalRequest) {
          if (
            originalRequest.servicePathObj &&
            originalRequest.servicePathObj.isAuth
          ) {
            redirectLoginPage(_ctx)
          } else {
            redirectCurrentPage(_ctx)
          }
        }
      }
    }

    return Promise.reject(error)
  }
)

publicApiInterface.interceptors.response.use(
  (response) => {
    return Promise.resolve(response)
  },
  (error) => {
    const code = parseInt(error.response && error.response.status)
    let originalRequest = error.config

    if (code == 401) {
      if (!originalRequest.isRetry) {
        originalRequest.isRetry = true
        return setPublicToken(_ctx).then((bearerToken) => {
          originalRequest.headers.Authorization = bearerToken
          return publicApiInterface(originalRequest)
        })
      }
    }

    return Promise.reject(error)
  }
)

export const setPublicToken = async (ctx) => {
  const params = {
    client_type: 4,
    grant_type: 'password',
    client_id: 'BlueCollarWebReactPublicClient',
    client_secret: 'c38fa88c-0d55-453e-b3e6-6cabef62a8a0',
  }

  const config = {
    path: '/api/token',
    type: 'post',
  }

  return wrapperTokenApi(ctx, config, params)
    .then((resp) => {
      const bearerToken = `Bearer ${resp.data.access_token}`
      setCookies(ctx, 'BlueCollar_PublicToken', bearerToken, {
        expires: 1,
        // httpOnly: true,
        // sameSite: 'Lax',
        // domain: '.isinolsun.com',
        // secure: true, //process.env.NEXT_PUBLIC_BUILD_ENV != 'local',
      })
      if (ctx) ctx.bearerToken = bearerToken
      return bearerToken
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const setUserToken = async (
  ctx,
  originalRequest,
  phone,
  deviceId,
  grandCode
) => {
  const number = '+905' + phone

  const params = {
    account_type: 2,
    client_type: 20,
    username: number,
    grant_type: 'password',
    client_id: 'BlueCollarWebUserClient',
    client_secret: 'A79733CC-F67D-44AD-8DC7-6829846912CE',
    device_id: deviceId,
    password: grandCode || deviceId,
  }

  const config = {
    path: '/api/token',
    type: 'post',
  }

  return wrapperTokenApi(ctx, config, params)
    .then((resp) => {
      setToken(ctx, resp.data.access_token)
      const bearerToken = `Bearer ${resp.data.access_token}`
      if (ctx) ctx.bearerToken = bearerToken
      return bearerToken
    })
    .catch((error) => {
      logout(ctx)
      if (originalRequest) {
        if (
          originalRequest.servicePathObj &&
          originalRequest.servicePathObj.isAuth
        ) {
          redirectLoginPage(ctx)
        } else {
          redirectCurrentPage(ctx)
        }
      }

      return Promise.reject(error)
    })
}

export default {
  configs,
  wrapper,
  wrapperTokenApi,
  returnDeviceId,
}
