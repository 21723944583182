const SENTRY_IGNORED_ERRORS = [
  "Failed to read the 'sessionStorage'",
  "Failed to read the 'localStorage'",
  'Failed to load client build manifest',
  'Failed to register a ServiceWorker for scope',
  'No available storage method found',
  'NotSupportedError: Failed to register a ServiceWorker for scope',
  'Non-Error promise rejection captured with value',
  'The operation is insecure',
  "Can't find variable: Visilabs",
  'Visilabs is not defined',
  // /Network Error/i,
  // /Fetch Error/i,
]

const sentryOptions = {
  hideSourceMaps: true,
  disableServerWebpackPlugin: true,
  disableClientWebpackPlugin: true,
  whitelistUrls: [/^(.+\.)*isinolsun\.com$/i, /^(.+\.)*mncdn\.com$/i],
  ignoreErrors: SENTRY_IGNORED_ERRORS,
  denyUrls: [
    /pagead\/js/i,
    /pagead\/ppub_config/i,
    /googletagservices\.com\/tag\/js\/gpt\.js/i,
    /googlesyndication\.com/i,
    /doubleclick\.net/i,
    /pcdn\.dengage\.com/i,
    /wis\.dengage\.com/i,
    /cdn\.taboola\.com/i,
    /vsh\.visilabs\.net/i,
    /analytics\.tiktok\.com/i,
    /clarity\.ms/i,
    /static\.hotjar\.com/i,
    /wps\.relateddigital\.com/i,
    /efilli\.com/i,
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /127\.0\.0\.1:4001\/isrunning/i,
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
}

module.exports = sentryOptions
