import { useEffect } from 'react'
import Router from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const ProgressBar = () => {
  NProgress.configure({ showSpinner: false })

  useEffect(() => {
    const start = () => {
      NProgress.start()
    }

    const done = () => {
      NProgress.done()
    }

    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', done)
    Router.events.on('routeChangeError', done)

    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', done)
      Router.events.off('routeChangeError', done)
    }
  }, [])

  return (
    <>
      <style jsx global>{`
        #nprogress .bar {
          background: #f3001b;
          height: 3px;
        }
        #nprogress .peg {
          box-shadow: none;
        }
      `}</style>
    </>
  )
}

export default ProgressBar
